import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts/layout';

import { Banner } from '../components';

import pracovnePonukyImg from '../assets/img/pracovne-ponuky.jpg';

const PracovnePonukyPage = () => (
  <Layout>
    <Banner imgSrc={pracovnePonukyImg} isSmall strength={0}>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h1
              className="h1 banner__heading text-center"
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1200"
            >
              PRACOVNÉ PONUKY
            </h1>
          </div>
        </div>
      </div>
    </Banner>
    <section className="box">
      <div className="container">
        <div className="row mb-24">
          <div className="col-12">
            <ul>
              <li>
                <strong>Voľné ponúkané pozície: </strong>
                Elektrikár
              </li>
              <li>
                <strong>Druh pracovného pomeru: </strong>
                Druh pracovného pomeru: Živnosť, HPP alebo vedľajší pracovný
                pomer, prípadne dohoda.
              </li>
              <li>
                <strong>Termín nástupu: </strong>
                Dohodou
              </li>
              <li>
                <strong>Ponúkaný plat: </strong>
                5&euro; až 12&euro;/hod., podľa okolností a dohody
              </li>
              <li>
                <strong>Miesto výkonu práce: </strong>
                Slovensko
              </li>
            </ul>
            <p>
              Hľadáme spoľahlivého a šikovného elektrikára, kvalifikovaného v
              oblasti elektroinštalačných prác pre dlhodobé stavebné projekty
              realizované na Slovensku a Rakúsku. V prípade potreby zaučíme.
            </p>
            <h4 className="text-color-primary">Náplň práce</h4>
            <ul>
              <li>
                Vo veľkej miere používame moduly inteligentnej elektroinštalácie
                značky{' '}
                <a
                  href="https://taphome.com/sk/?v=13dd621f2711"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-color-primary"
                >
                  TapHome
                </a>
              </li>
              <li>Vykonávanie montážnych prác na zariadení VN a NN</li>
              <li>Výstavba elektrických vedení</li>
              <li>
                Plnenie operatívnych úloh útvaru podľa pokynov nadriadeného
              </li>
              <li>Čítanie technickej dokumentácie</li>
              <li>Navrhovanie rozmiestnenia elektrického vedenia</li>
              <li>Elektroinštalácie v priemysle</li>
              <li>Výroba rozvádzačov</li>
              <li>
                Diagnostikovanie a odstraňovanie porúch a chýb na elektrickom
                vedení
              </li>
              <li>Dodržiavanie bezpečnostných a protipožiarnych predpisov</li>
              <li>Starostlivosť o zverený majetok firmy</li>
            </ul>
            <h4 className="text-color-primary">
              Osobnostné predpoklady a zručnosti
            </h4>
            <ul>
              <li>
                Odborná spôsobilosť podľa{' '}
                <strong>vyhlášky č. 508/2009 Z. z. min. § 22</strong>
              </li>
              <li>Zodpovednosť, samostatnosť, spoľahlivosť, dôslednosť</li>
              <li>Schopnosť tímovej spolupráce</li>
              <li>Manuálna zručnosť</li>
              <li>Výborná fyzická kondícia</li>
            </ul>
            <p>
              <strong>Jazykové znalosti:</strong>
            </p>
            <ul>
              <li>Slovenský jazyk</li>
              <li>Nemecký jazyk ( výhodou )</li>
            </ul>
            <p>
              <strong>Vodičský preukaz typ:</strong>
            </p>
            <p>B</p>
            <p>
              <strong>Minimálna požadovaná prax v odbore:</strong>
            </p>
            <p>1 rok</p>
            <p className="text-color-primary">
              V prípade záujmu zašlite svoj životopis na e-mailovú adresu{' '}
              <a href="mailto:info@icsgroup.sk" className="text-color-grey">
                info@icsgroup.sk
              </a>
              , alebo použite kontaktný formulár:
            </p>
            <p />
            <p className="text-color-primary" />
            <Link to="/kontakt#contact-form" className="button">
              Napíšte nám
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default PracovnePonukyPage;

export function Head() {
  return (
    <>
      <title>
        Pracovné ponuky pre elektrikára, elektroinštalatéra, ... | ICS Group,
        s.r.o.
      </title>
      <meta
        name="description"
        content="Pracovné ponuky u nás aj v zahraničí."
      />
      <meta
        name="keywords"
        content="automatizácie, zabezpečovacie systémy, inteligentná domácnosť"
      />
    </>
  );
}
